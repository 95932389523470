.works {
  background-image: url("../../img/glassegg-our-work.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.swiper-button-prev {
  position: absolute;
  width: 40px;
  height: 40px;
  /* background-image: url(./next.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center; */
  left: 25px;
  top: 100px;
  z-index: 300;
  cursor: pointer;
}
.swiper-button-next {
  position: absolute;
  width: 40px;
  height: 40px;
  /* background-image: url(./next.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center; */
  right: 25px;
  top: 100px;
  z-index: 300;
  cursor: pointer;
}
