@media (min-width: 1440px) {
}

@media (min-width: 1024px) {
  
}



@media (min-width: 992px) {

}

@media (min-width: 768px) {
  .footer {
    width: 100%;
    display: block;
  }
  .map {
    position: relative;
}

.flag {
  position: absolute;
  z-index: 1;
  left: 25%;
}
}

@media (min-width: 576px) {
}

@media (min-width: 481px) {
}

@media (min-width: 425px) {
  
}

@media (min-width: 375px) {
  
}

@media (min-width: 320px) {
  .timeline-custom {
    top: 45px;
    height: 100px;
  }
}

@media (max-width: 1439px) {
}
@media (min-width: 1279px) {

}
@media (max-width: 1023px) {
  .carouselAbout {
    width: 100%;
  }

}
@media (max-width: 991px) {
}
@media (max-width: 767px) {
  .box-intro {
    width: 50%;
    margin: 15px;
    flex-direction: column;
    box-align: center;
  }
  .timeline-custom {
    height: 250px;
    top: 60px;
  }
}
@media (max-width: 575px) {
  .carouselService {
    width: 750px;
    top: 60px;
  }
  .introService {
    width: 100%;
    margin: 20px;
  }
  .bannerService {
    width: 500px;
    margin: 20px;
  }
  .pictureNews {
    width: 260px;
    height: 200px;
  }
}

@media (max-width: 480px) {
  
}
@media (max-width: 425px) {
}
@media (max-width: 374px) {
}
