/* .works {
  /* background-image: url("../../img/glassegg-our-work.png"); */
/* background-size: cover;
  background-repeat: no-repeat;
} */

.swiper-button-prev-timeline {
  /* position: absolute; */
  width: 40px;
  height: 40px;
  display: inline-block;
  /* background-image: url(./next.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center; */
  /* left: 25px;
  top: 100px; */
  z-index: 300;
  cursor: pointer;
  color: red;
}
.swiper-button-next-timeline {
  /* position: absolute; */
  width: 40px;
  height: 40px;
  /* background-image: url(./next.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center; */
  /* right: 25px;
  top: 250px; */
  z-index: 300;
  display: inline-block;
  cursor: pointer;
  color: red;
}

.timeline-custom {
  height: 300px;
  width: 100%;
  position: absolute;
  top: 200px;
  bottom: 0px;
  display: flex;
  align-items: flex-end;
}


.timeline-banner {
  position: relative;
  height: 500px;
}
