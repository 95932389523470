header {
  position: relative;
  width: 100%;
  z-index: 40;
  background: white;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-box-shadow: 0px 0px 10px #cccccc;
  -moz-box-shadow: 0px 0px 10px #cccccc;
  -ms-box-shadow: 0px 0px 10px #cccccc;
  box-shadow: 0px 0px 10px #cccccc;
  /* height: 109px; */
}
.active-header {
  border-bottom: rgb(9, 168, 216) 4px solid;
  padding-bottom: 3px;
  transition: all 200ms ease-in-out;
}

header.fix-header {
  position: fixed;
  top: 0px;
  left: 0px;
  /* height: 90px; */
}

.navbar {
  transition: all 330ms ease-out;
}

.navbar-open {
  transform: translateX(0%);
}
.navbar-close {
  transform: translateX(-100%);
}
